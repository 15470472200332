<template>
    <div>
        <b-container>
            <section class="categories pt-0">
                <div class="sector mb-0">
                    <h2 class="main-title">Cities</h2>
                    <p class="main-subtitle">Browse companies by the city</p>
                    <b-row>
                        <b-col cols="12">
                            <div class="region">
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'United States' } }" class="region__country">United States</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'New York' } }" class="interaction-button interaction-button--default ml-0 mb-2">New York</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Los Angeles' } }" class="interaction-button interaction-button--default ml-0 mb-2">Los Angeles</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'San Francisco' } }" class="interaction-button interaction-button--default ml-0 mb-2">San Francisco</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Chicago' } }" class="interaction-button interaction-button--default ml-0 mb-2">Chicago</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'San Diego' } }" class="interaction-button interaction-button--default ml-0 mb-2">San Diego</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Seattle' } }" class="interaction-button interaction-button--default ml-0 mb-2">Seattle</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Miami' } }" class="interaction-button interaction-button--default ml-0 mb-2">Miami</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Austin' } }" class="interaction-button interaction-button--default ml-0 mb-2">Austin</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Houston' } }" class="interaction-button interaction-button--default ml-0 mb-2">Houston</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Detroit' } }" class="interaction-button interaction-button--default ml-0 mb-2">Detroit</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Dallas' } }" class="interaction-button interaction-button--default ml-0 mb-2">Dallas</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Atlanta' } }" class="interaction-button interaction-button--default ml-0 mb-2">Atlanta</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Cleveland' } }" class="interaction-button interaction-button--default ml-0 mb-2">Cleveland</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Denver' } }" class="interaction-button interaction-button--default ml-0 mb-2">Denver</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Orlando' } }" class="interaction-button interaction-button--default ml-0 mb-2">Orlando</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Tampa' } }" class="interaction-button interaction-button--default ml-0 mb-2">Tampa</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Portland' } }" class="interaction-button interaction-button--default ml-0 mb-2">Portland</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Cincinnati' } }" class="interaction-button interaction-button--default ml-0 mb-2">Cincinnati</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Baltimore' } }" class="interaction-button interaction-button--default ml-0 mb-2">Baltimore</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'St. Louis' } }" class="interaction-button interaction-button--default ml-0 mb-2">St. Louis</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Charleston' } }" class="interaction-button interaction-button--default ml-0 mb-2">Charleston</router-link>
                            </div>
                            <div class="region">
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'United Kingdom' } }" class="region__country">United Kingdom</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'London' } }" class="interaction-button interaction-button--default ml-0 mb-2">London</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Manchester' } }" class="interaction-button interaction-button--default ml-0 mb-2">Manchester</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Birmingham' } }" class="interaction-button interaction-button--default ml-0 mb-2">Birmingham</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Leeds' } }" class="interaction-button interaction-button--default ml-0 mb-2">Leeds</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Newcastle' } }" class="interaction-button interaction-button--default ml-0 mb-2">Newcastle</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Nottingham' } }" class="interaction-button interaction-button--default ml-0 mb-2">Nottingham</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Edinburgh' } }" class="interaction-button interaction-button--default ml-0 mb-2">Edinburgh</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Liverpool' } }" class="interaction-button interaction-button--default ml-0 mb-2">Liverpool</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Sheffield' } }" class="interaction-button interaction-button--default ml-0 mb-2">Sheffield</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Brighton' } }" class="interaction-button interaction-button--default ml-0 mb-2">Brighton</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Bristol' } }" class="interaction-button interaction-button--default ml-0 mb-2">Bristol</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Southampton' } }" class="interaction-button interaction-button--default ml-0 mb-2">Southampton</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Glasgow' } }" class="interaction-button interaction-button--default ml-0 mb-2">Glasgow</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Oxford' } }" class="interaction-button interaction-button--default ml-0 mb-2">Oxford</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Bournemouth' } }" class="interaction-button interaction-button--default ml-0 mb-2">Bournemouth</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Cardiff' } }" class="interaction-button interaction-button--default ml-0 mb-2">Cardiff</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Bath' } }" class="interaction-button interaction-button--default ml-0 mb-2">Bath</router-link>
                            </div>
                            <div class="region pb-0">
                                <span class="region__country region__country--none">Australia and Oceania</span>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Australia' } }" class="region__country">Australia</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Melbourne' } }" class="interaction-button interaction-button--default ml-0 mb-2">Melbourne</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Sydney' } }" class="interaction-button interaction-button--default ml-0 mb-2">Sydney</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Brisbane' } }" class="interaction-button interaction-button--default ml-0 mb-2">Brisbane</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Perth' } }" class="interaction-button interaction-button--default ml-0 mb-2">Perth</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Hobart' } }" class="interaction-button interaction-button--default ml-0 mb-2">Hobart</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'New Zealand' } }" class="region__country">New&nbsp;Zealand</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Auckland' } }" class="interaction-button interaction-button--default ml-0 mb-2">Auckland</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Wellington' } }" class="interaction-button interaction-button--default ml-0 mb-2">Wellington</router-link>
                                </div>
                            </div>
                            <div class="region">
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Canada' } }" class="region__country">Canada</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Montreal' } }" class="interaction-button interaction-button--default ml-0 mb-2">Montreal</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Toronto' } }" class="interaction-button interaction-button--default ml-0 mb-2">Toronto</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Vancouver' } }" class="interaction-button interaction-button--default ml-0 mb-2">Vancouver</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Ottawa' } }" class="interaction-button interaction-button--default ml-0 mb-2">Ottawa</router-link>
                                <router-link :to="{ name: 'agencies', query: { agencyParam: 'Calgary' } }" class="interaction-button interaction-button--default ml-0 mb-2">Calgary</router-link>
                            </div>
                            <div class="region pb-0">
                                <span class="region__country region__country--none">Europe</span>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Germany' } }" class="region__country">Germany</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Berlin' } }" class="interaction-button interaction-button--default ml-0 mb-2">Berlin</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Munich' } }" class="interaction-button interaction-button--default ml-0 mb-2">Munich</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Hamburg' } }" class="interaction-button interaction-button--default ml-0 mb-2">Hamburg</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Frankfurt' } }" class="interaction-button interaction-button--default ml-0 mb-2">Frankfurt</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Hannover' } }" class="interaction-button interaction-button--default ml-0 mb-2">Hannover</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Spain' } }" class="region__country">Spain</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Madrid' } }" class="interaction-button interaction-button--default ml-0 mb-2">Madrid</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Barcelona' } }" class="interaction-button interaction-button--default ml-0 mb-2">Barcelona</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Sevilla' } }" class="interaction-button interaction-button--default ml-0 mb-2">Sevilla</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Valencia' } }" class="interaction-button interaction-button--default ml-0 mb-2">Valencia</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'France' } }" class="region__country">France</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Paris' } }" class="interaction-button interaction-button--default ml-0 mb-2">Paris</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Marseille' } }" class="interaction-button interaction-button--default ml-0 mb-2">Marseille</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Lyon' } }" class="interaction-button interaction-button--default ml-0 mb-2">Lyon</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Bordeaux' } }" class="interaction-button interaction-button--default ml-0 mb-2">Bordeaux</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Poland' } }" class="region__country">Poland</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Warsaw' } }" class="interaction-button interaction-button--default ml-0 mb-2">Warsaw</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Krakow' } }" class="interaction-button interaction-button--default ml-0 mb-2">Krakow</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Wroclaw' } }" class="interaction-button interaction-button--default ml-0 mb-2">Wroclaw</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Ukraine' } }" class="region__country">Ukraine</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Kiev' } }" class="interaction-button interaction-button--default ml-0 mb-2">Kiev</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Lviv' } }" class="interaction-button interaction-button--default ml-0 mb-2">Lviv</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Dnipro' } }" class="interaction-button interaction-button--default ml-0 mb-2">Dnipro</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Italy' } }" class="region__country">Italy</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Rome' } }" class="interaction-button interaction-button--default ml-0 mb-2">Rome</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Milan' } }" class="interaction-button interaction-button--default ml-0 mb-2">Milan</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Belgium' } }" class="region__country">Belgium</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Brussels' } }" class="interaction-button interaction-button--default ml-0 mb-2">Brussels</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Antwerp' } }" class="interaction-button interaction-button--default ml-0 mb-2">Antwerp</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Switzerland' } }" class="region__country">Switzerland</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Zurich' } }" class="interaction-button interaction-button--default ml-0 mb-2">Zurich</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Geneva' } }" class="interaction-button interaction-button--default ml-0 mb-2">Geneva</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Sweden' } }" class="region__country">Sweden</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Stockholm' } }" class="interaction-button interaction-button--default ml-0 mb-2">Stockholm</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Gothenburg' } }" class="interaction-button interaction-button--default ml-0 mb-2">Gothenburg</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Portugal' } }" class="region__country">Portugal</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Lisbon' } }" class="interaction-button interaction-button--default ml-0 mb-2">Lisbon</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Porto' } }" class="interaction-button interaction-button--default ml-0 mb-2">Porto</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Denmark' } }" class="region__country">Denmark</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Copenhagen' } }" class="interaction-button interaction-button--default ml-0 mb-2">Copenhagen</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Ireland' } }" class="region__country">Ireland</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Dublin' } }" class="interaction-button interaction-button--default ml-0 mb-2">Dublin</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Netherlands' } }" class="region__country">Netherlands</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Amsterdam' } }" class="interaction-button interaction-button--default ml-0 mb-2">Amsterdam</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Austria' } }" class="region__country">Austria</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Vienna' } }" class="interaction-button interaction-button--default ml-0 mb-2">Vienna</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Greece' } }" class="region__country">Greece</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Athens' } }" class="interaction-button interaction-button--default ml-0 mb-2">Athens</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Czech Republic' } }" class="region__country">Czechia</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Prague' } }" class="interaction-button interaction-button--default ml-0 mb-2">Prague</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Slovakia' } }" class="region__country">Slovakia</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Bratislava' } }" class="interaction-button interaction-button--default ml-0 mb-2">Bratislava</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Croatia' } }" class="region__country">Croatia</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Zagreb' } }" class="interaction-button interaction-button--default ml-0 mb-2">Zagreb</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Estonia' } }" class="region__country">Estonia</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Tallinn' } }" class="interaction-button interaction-button--default ml-0 mb-2">Tallinn</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Lithuania' } }" class="region__country">Lithuania</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Vilnius' } }" class="interaction-button interaction-button--default ml-0 mb-2">Vilnius</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Russian Federation' } }" class="region__country">Russia</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Moscow' } }" class="interaction-button interaction-button--default ml-0 mb-2">Moscow</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Belarus' } }" class="region__country">Belarus</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Minsk' } }" class="interaction-button interaction-button--default ml-0 mb-2">Minsk</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Hungary' } }" class="region__country">Hungary</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Budapest' } }" class="interaction-button interaction-button--default ml-0 mb-2">Budapest</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Romania' } }" class="region__country">Romania</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Bucharest' } }" class="interaction-button interaction-button--default ml-0 mb-2">Bucharest</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Bulgaria' } }" class="region__country">Bulgaria</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Sofia' } }" class="interaction-button interaction-button--default ml-0 mb-2">Sofia</router-link>
                                </div>
                            </div>
                            <div class="region pb-0">
                                <span class="region__country region__country--none">Asia</span>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'United Arab Emirates' } }" class="region__country">United&nbsp;Arab&nbsp;Emirates</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Dubai' } }" class="interaction-button interaction-button--default ml-0 mb-2">Dubai</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Abu Dhabi' } }" class="interaction-button interaction-button--default ml-0 mb-2">Abu Dhabi</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'India' } }" class="region__country">India</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'New Delhi' } }" class="interaction-button interaction-button--default ml-0 mb-2">New Delhi</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Mumbai' } }" class="interaction-button interaction-button--default ml-0 mb-2">Mumbai</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Hong Kong' } }" class="region__country">Hong Kong</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Hong Kong' } }" class="interaction-button interaction-button--default ml-0 mb-2">Hong Kong</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'China' } }" class="region__country">China</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Shanghai' } }" class="interaction-button interaction-button--default ml-0 mb-2">Shanghai</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Japan' } }" class="region__country">Japan</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Tokyo' } }" class="interaction-button interaction-button--default ml-0 mb-2">Tokyo</router-link>
                                </div>
                                <div class="region region--inline" style="width: 135px;">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'South Korea' } }" class="region__country">South&nbsp;Korea</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Seoul' } }" class="interaction-button interaction-button--default ml-0 mb-2">Seoul</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Singapore' } }" class="region__country">Singapore</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Singapore' } }" class="interaction-button interaction-button--default ml-0 mb-2">Singapore</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Philippines' } }" class="region__country">Philippines</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Manila' } }" class="interaction-button interaction-button--default ml-0 mb-2">Manila</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Vietnam' } }" class="region__country">Vietnam</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Ho Chi Minh City' } }" class="interaction-button interaction-button--default ml-0 mb-2">Ho Chi Minh City</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Indonesia' } }" class="region__country">Indonesia</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Jakarta' } }" class="interaction-button interaction-button--default ml-0 mb-2">Jakarta</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Turkey' } }" class="region__country">Turkey</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Istanbul' } }" class="interaction-button interaction-button--default ml-0 mb-2">Istanbul</router-link>
                                </div>
                                <div class="region region--inline" style="width: 135px;">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Bangladesh' } }" class="region__country">Bangladesh</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Dhaka' } }" class="interaction-button interaction-button--default ml-0 mb-2">Dhaka</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Israel' } }" class="region__country">Israel</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Tel Aviv' } }" class="interaction-button interaction-button--default ml-0 mb-2">Tel Aviv</router-link>
                                </div>
                            </div>
                            <div class="region pb-0">
                                <span class="region__country region__country--none">Latin America</span>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Brazil' } }" class="region__country">Brazil</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Sao Paulo' } }" class="interaction-button interaction-button--default ml-0 mb-2">Sao Paulo</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Argentina' } }" class="region__country">Argentina</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Buenos Aires' } }" class="interaction-button interaction-button--default ml-0 mb-2">Buenos Aires</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Chile' } }" class="region__country">Chile</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Santiago' } }" class="interaction-button interaction-button--default ml-0 mb-2">Santiago</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Peru' } }" class="region__country">Peru</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Lima' } }" class="interaction-button interaction-button--default ml-0 mb-2">Lima</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Colombia' } }" class="region__country">Colombia</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Bogota' } }" class="interaction-button interaction-button--default ml-0 mb-2">Bogota</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Panama' } }" class="region__country">Panama</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Panama' } }" class="interaction-button interaction-button--default ml-0 mb-2">Panama</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Uruguay' } }" class="region__country">Uruguay</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Montevideo' } }" class="interaction-button interaction-button--default ml-0 mb-2">Montevideo</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Paraguay' } }" class="region__country">Paraguay</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Asunción' } }" class="interaction-button interaction-button--default ml-0 mb-2">Asunción</router-link>
                                </div>
                            </div>
                            <div class="region pb-0">
                                <span class="region__country region__country--none">Africa</span>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'South Africa' } }" class="region__country">South Africa</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Johannesburg' } }" class="interaction-button interaction-button--default ml-0 mb-2">Johannesburg</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Pretoria' } }" class="interaction-button interaction-button--default ml-0 mb-2">Pretoria</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Egypt' } }" class="region__country">Egypt</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Cairo' } }" class="interaction-button interaction-button--default ml-0 mb-2">Cairo</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Nigeria' } }" class="region__country">Nigeria</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Lagos' } }" class="interaction-button interaction-button--default ml-0 mb-2">Lagos</router-link>
                                </div>
                                <div class="region region--inline">
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Tanzania' } }" class="region__country">Tanzania</router-link>
                                    <router-link :to="{ name: 'agencies', query: { agencyParam: 'Dar Es Salaam' } }" class="interaction-button interaction-button--default ml-0 mb-2">Dar Es Salaam</router-link>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center">
                        <router-link to="/agencies" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View more</router-link>
                    </b-row>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
export default {
  components: {
    SimpleLineIcons
  }
}
</script>
